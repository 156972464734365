
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import { getLedgerAll } from "@/api/request/item";
import Pagination from "@/comp/Pagination/index.vue";
import { getHongBaoYuList, saveHongBaoYu, setHongBaoYuStatus } from "@/api/request/activity";

//组件
@Component({
  name: "HongBaoYu",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private propOptions: any[] = [];
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //临时数据
    time: [],

    //页面数据
    page: 1, // 当前页
    page_size: 10, // 页大小

    //时间数据
    end_date: "", // 结束时间，格式：2024-01-29
    begin_date: "", // 开始时间，格式： 2024-01-29

    //请求数据
    status: -1, //状态
    country: "", //归属国家
    effect_hour: -1, //生效时间
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
    };
  }

  //时间列表
  private hourList: any[] = [
    { hour: -1, name: "全部时间" },
    { hour: 0, name: "凌晨" },
    { hour: 1, name: "上午1点" },
    { hour: 2, name: "上午2点" },
    { hour: 3, name: "上午3点" },
    { hour: 4, name: "上午4点" },
    { hour: 5, name: "上午5点" },
    { hour: 6, name: "上午6点" },
    { hour: 7, name: "上午7点" },
    { hour: 8, name: "上午8点" },
    { hour: 9, name: "上午9点" },
    { hour: 10, name: "上午10点" },
    { hour: 11, name: "上午11点" },
    { hour: 12, name: "上午12点" },
    { hour: 13, name: "下午1点" },
    { hour: 14, name: "下午2点" },
    { hour: 15, name: "下午3点" },
    { hour: 16, name: "下午4点" },
    { hour: 17, name: "下午5点" },
    { hour: 18, name: "下午6点" },
    { hour: 19, name: "下午7点" },
    { hour: 20, name: "下午8点" },
    { hour: 21, name: "下午9点" },
    { hour: 22, name: "下午10点" },
    { hour: 23, name: "下午11点" },
  ];

  //状态列表
  private statusList: any[] = [
    { status: -1, name: "全部状态" },
    { status: 0, name: "草稿" },
    { status: 1, name: "启用" },
    { status: 2, name: "停用" },
    { status: 3, name: "删除" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取道具列表
    this.getPropList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_date = this.listQuery.time[1];
      this.listQuery.begin_date = this.listQuery.time[0];
    } else {
      this.listQuery.end_date = "";
      this.listQuery.begin_date = "";
    }

    //获取数据
    const { data } = await getHongBaoYuList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具列表
  private async getPropList() {
    //显示等待
    this.listLoading = true;

    //获取道具数据
    const res = await getLedgerAll();

    //数据赋值
    this.propOptions = res.data;
  }

  //处理添加
  private handleAdd(): void {
    //表单数据
    this.form = {
      //临时数据
      time: [], //时间

      //上传参数
      prices: [], //奖品
      duration: 0, //持续时长
      country: "", //归属国家
      id: undefined, //红包雨ID
      end_date: "", // 结束时间
      begin_date: "", //开始时间
      effect_hour: -1, //生效小时
    };

    //显示界面
    this.isCreate = true;
    this.dialogShow = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      time: [row.begin_date, row.end_date], //时间

      //上传参数
      id: row.id, //红包雨ID
      prices: row.prices, //奖品
      country: row.country, //归属国家
      duration: row.duration, //持续时长
      end_date: row.end_date, // 结束时间
      begin_date: row.begin_date, //开始时间
      effect_hour: row.effect_hour, //生效小时
    };

    //显示界面
    this.isCreate = false;
    this.dialogShow = true;
  }

  //生效时间赋值
  private assEffectTime(hour: number): string {
    //数据赋值
    var effectHour: string = "";
    for (var i: number = 0; i < this.hourList.length; i++) {
      if (hour == this.hourList[i].hour) {
        effectHour = this.hourList[i].name;
        break;
      }
    }

    //返回数据
    return effectHour;
  }

  //奖品赋值
  private assShowPrices(prices: any[]): string {
    //数据赋值
    var allPrices: string = "";
    for (var i: number = 0; i < prices.length; i++) {
      allPrices += prices[i].prop_name + " | ";
    }
    allPrices = allPrices.substring(0, allPrices.length - 3);

    //返回数据
    return allPrices;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //处理状态
  private async handleStatus(row: any, status: number) {
    //显示等待
    this.listLoading = true;

    //获取数据
    await setHongBaoYuStatus({ id: row.id, status: status });

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.listLoading = false;

      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //------------------------------ 添加/编辑活动 -----------------------------
  //定义变量
  private isCreate: boolean = false; //是否创建
  private dialogShow: boolean = false; //显示随画框
  private dialogLoading: boolean = false; //对话框等待

  //表单数据
  private form: any = {
    //临时数据
    time: [], //时间

    //上传参数
    prices: [], //奖品
    duration: 0, //持续时长
    country: "", //归属国家
    id: undefined, //红包雨ID
    end_date: "", // 结束时间
    begin_date: "", //开始时间
    effect_hour: -1, //生效时间
  };

  //时间列表
  private hourAddList: any[] = [
    { hour: -1, name: "请选择生效时间" },
    { hour: 0, name: "凌晨" },
    { hour: 1, name: "上午1点" },
    { hour: 2, name: "上午2点" },
    { hour: 3, name: "上午3点" },
    { hour: 4, name: "上午4点" },
    { hour: 5, name: "上午5点" },
    { hour: 6, name: "上午6点" },
    { hour: 7, name: "上午7点" },
    { hour: 8, name: "上午8点" },
    { hour: 9, name: "上午9点" },
    { hour: 10, name: "上午10点" },
    { hour: 11, name: "上午11点" },
    { hour: 12, name: "上午12点" },
    { hour: 13, name: "下午1点" },
    { hour: 14, name: "下午2点" },
    { hour: 15, name: "下午3点" },
    { hour: 16, name: "下午4点" },
    { hour: 17, name: "下午5点" },
    { hour: 18, name: "下午6点" },
    { hour: 19, name: "下午7点" },
    { hour: 20, name: "下午8点" },
    { hour: 21, name: "下午9点" },
    { hour: 22, name: "下午10点" },
    { hour: 23, name: "下午11点" },
  ];

  //添加道具
  private addProp(): void {
    //数据赋值
    this.sendPropOptions = [];
    for (var i: number = 0; i < this.propOptions.length; i++) {
      //数据赋值
      var isAdd: boolean = false;
      for (var j: number = 0; j < this.form.prices.length; j++) {
        if (this.propOptions[i].propid == this.form.prices[j].prop_id) {
          isAdd = true;
          break;
        }
      }
      if (!isAdd) this.sendPropOptions.push(this.propOptions[i]);
    }

    //显示界面
    this.sendDialogShow = true;
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;

    //获取列表
    this.getList();
  }

  //创建活动
  private async createHandle() {
    //判断返回
    if (this.form.time.length == 0) return this.$message.error("请选择活动时间");
    if (this.form.effect_hour == -1) return this.$message.error("请选择生效时间");
    if (this.form.prices.length == 0) return this.$message.error("请添加活动奖品");
    if (this.form.duration == 0 || this.form.duration > 3600) return this.$message.error("持续时长为 1 ~ 3600 之间的数值");

    //监测奖品
    var totalRate: number = 0;
    for (var i: number = 0; i < this.form.prices.length; i++) {
      //概率
      if (Number(this.form.prices[i].rate) == 0 || Number(this.form.prices[i].rate) > 1) {
        return this.$message.error("概率为 0 ~ 1 的数值");
      } else {
        this.form.prices[i].rate = Number(this.form.prices[i].rate);
      }

      //数量
      if (Number(this.form.prices[i].prop_num) == 0) {
        return this.$message.error("数量 > 0");
      } else {
        this.form.prices[i].prop_num = Number(this.form.prices[i].prop_num);
      }

      //排序
      this.form.prices[i].sort = Number(this.form.prices[i].sort);

      //数据赋值
      totalRate += this.form.prices[i].rate;
    }

    //总概率
    if (totalRate != 1) return this.$message.error("概率加起来必须为1");

    //显示等待
    this.dialogLoading = true;

    //时间赋值
    this.form.end_date = this.form.time[1];
    this.form.begin_date = this.form.time[0];

    //获取道具数据
    await saveHongBaoYu(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏界面
      this.dialogShow = false;

      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //更新活动
  private async updataHandle() {
    //判断返回
    if (this.form.duration == 0) return this.$message.error("请输入持续时长");
    if (this.form.time.length == 0) return this.$message.error("请选择活动时间");
    if (this.form.effect_hour == -1) return this.$message.error("请选择生效时间");
    if (this.form.prices.length == 0) return this.$message.error("请添加活动奖品");

    //监测奖品
    var totalRate: number = 0;
    for (var i: number = 0; i < this.form.prices.length; i++) {
      //概率
      if (Number(this.form.prices[i].rate) == 0 || Number(this.form.prices[i].rate) > 1) {
        return this.$message.error("概率为 0 ~ 1 的数值");
      } else {
        this.form.prices[i].rate = Number(this.form.prices[i].rate);
      }

      //数量
      if (Number(this.form.prices[i].prop_num) == 0) {
        return this.$message.error("数量 > 0");
      } else {
        this.form.prices[i].prop_num = Number(this.form.prices[i].prop_num);
      }

      //排序
      this.form.prices[i].sort = Number(this.form.prices[i].sort);

      //数据赋值
      totalRate += this.form.prices[i].rate;
    }

    //总概率
    if (totalRate != 1) return this.$message.error("概率加起来必须为1");

    //显示等待
    this.dialogLoading = true;

    //时间赋值
    this.form.end_date = this.form.time[1];
    this.form.begin_date = this.form.time[0];

    //获取道具数据
    await saveHongBaoYu(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏界面
      this.dialogShow = false;

      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //删除礼包
  private delPropRow(index: any): void {
    //数据赋值
    this.form.prices.splice(index, 1);
  }

  //------------------------------ 添加/编辑活动 -----------------------------
  //定义变量
  private sendPropOptions: any[] = [];
  private sendDialogShow: boolean = false; //显示对话框

  //取消按钮
  private btnSendCancel(): void {
    //隐藏界面
    this.sendDialogShow = false;
  }

  //添加道具
  private addGift(row: any): void {
    //数据赋值
    this.form.prices.push({ prop_id: row.propid, prop_name: row.propname, prop_num: 1, rate: 0, sort: this.form.prices.length });

    //隐藏界面
    this.sendDialogShow = false;
  }
}
